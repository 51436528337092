<template>
<footer class="footer mt-auto py-3">
  <div class="container">
    <p class="text-center text-muted">© 2022</p>
  </div>
</footer>
</template>

<script>
export default {
  name: "PageFooter",
}
</script>
