import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import mitt from 'mitt';
const emitter = mitt();

router.afterEach(() => {
  emitter.emit('clearNotifications');
});

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app
.use(router)
.mount("#app");

