import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/HomePage.vue";
import Register from "../views/RegisterPage.vue";
import Login from "../views/LoginPage";
import Admin from "../views/AdminPage";
import Validate from "../views/ValidatePage";
import Faq from "../views/FaqPage";
import Changelog from "../views/ChangelogPage";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: Register,
    meta: { guest: true },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/podcastadmin",
    name: "AdminPage",
    component: Admin,
  },
  {
    path: "/validate",
    name: "ValidatePage",
    component: Validate,
  },
  {
    path: "/faq",
    name: "FaqPage",
    component: Faq,
  },
  {
    path: "/changelog",
    name: "ChangelogPage",
    component: Changelog,
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes,
});

export default router;
