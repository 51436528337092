<template>
  <div class="dashboard container">

    <div class="d-flex justify-content-center">
      <div
          class="spinner-border m-5"
          style="width: 4rem; height: 4rem;"
          role="status"
          v-show="loading"
        >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-if="emailUnverified" class="alert border bg-light">
        <h4>Ваш адрес не верифицирован</h4>
        <p>
          Вам было отправлено письмо с адреса podcast-rss@yandex.ru.
          Проверьте вашу почту и папку "Спам" на наличие письма с темой
          "Проверка почты PodcastRSS". Кликните по ссылке находящейся в письме.
        </p>
    </div>

    <div v-if="!podcastsData.length && !loading" class="alert border bg-light">
        <h4>Нет подкастов, где ты админ</h4>
        <p>Не ни одного подкаста, который ты бы администрировал.</p>
        <p><router-link to="/" class="nav-link">
          Вернутся на главную
        </router-link></p>
    </div>

    <div class="container">
      <div v-if="podcastsData" class="row row-cols-auto gy-3">
        <div v-for="(podcast, index) in podcastsData" :key="podcast" class="col" >
          <div class="p-3 border rounded bg-light">
            <img :src="podcast.logo_url" class="podcast-logo">
            <h4>{{podcast.podcast_title}} - {{index}}</h4>

            <Form @submit="submitUpdates">
            <Field name="podcastid" type="hidden" :value="podcast.podcast_id"/>
            <Field name="csrftoken" type="hidden" :value="podcast.csrf_token"/>

            <div class="mb-3">
              <input class="form-control" type="file" accept=".csv" id="formFile" @change="handleFileUpload( $event )">
              <label for="formFile" class="form-label">*.csv файл со списком подписчиков</label>
            </div>

            <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
            >
              {{ message }}
            </div>

            <div class="d-grid">
              <button class="btn btn-primary btn-block">Загрузить</button>
            </div>

            </Form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import apiClient from "../services/api-common";
import Papa from 'papaparse';
import { Form, Field } from "vee-validate";

export default {
  name: "AdminPage",
  components: {
    Form,
    Field
  },
  data() {
    return {
      loading: false,
      podcastsData: "",
      emailUnverified: false,
      file: '',
      content: [],
      parsed: false,
      message: ""
    }
  },
  methods: {
    async getPodcasts() {
      this.loading = true;
        apiClient.get("/api/podcastadmin").then(
        (response) => {
          if ("podcasts" in response.data)
            this.podcastsData = response.data.podcasts;
          if ("email-unverified" in response.data)
            this.emailUnverified = response.data["email-unverified"];
        }
      ).catch(
        (error) => {
          if ("response" in error) {
            switch (error.response.status) {
              case 401:
                this.$router.push('/login').then( () => {
                  this.emitter.emit('sendNotification', { text: 'Вы не авторизовались. Зайдите в систему.', type: "error" });
                });
                break;
              case 403:
                this.$router.push('/login').then( () => {
                  this.emitter.emit('sendNotification', { text: 'Ваши авторизационные данные устарели. Войдите в систему еще раз.', type: "error" });
                });
                break;
              default:
            }
          }

          var errorMessage =
            error.message ||
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.toString();
          this.emitter.emit('sendNotification', { text: errorMessage, type: "error" });
          }
        ).then(
          () => {
            this.loading = false;
          }
        );
    },
    handleFileUpload( event ){
      this.file = event.target.files[0];
      this.parseFile();
    },
    parseFile() {
      Papa.parse( this.file, {
          header: true,
          skipEmptyLines: true,
          complete: function( results ){
              this.content = results.data.map(element => ({
                email: element.email,
                start_date: element.start_date,
                end_date: element.end_date,
                level_name: element.level_name
              }));
              this.parsed = true;
          }.bind(this)
      } );
    },
    submitUpdates(val) {
      this.message = "";
      if (this.content.length == 0) {
        this.message = "Вы не загрузили файл";
        return;
      }
      this.loading = true;
      var data = {
        "podcast_id": val.podcastid,
        "csrf_token": val.csrftoken,
        "users": this.content
      };
      apiClient.post( '/api/updatesubscriptions',
          data
      ).then(() => {
        this.emitter.emit('sendNotification', { text: 'Список успешно загружен.', type: "success" });
      })
      .catch((error) => {
        console.log(JSON.stringify(error.response));

        var errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.emitter.emit('sendNotification', { text: errorMessage, type: "error" });
      }).then(
          () => {
            this.loading = false;
            this.content = [];
            this.getPodcasts();
          }
        );
    }
  },
  beforeMount(){
    this.getPodcasts();
  },
};
</script>

<style>
.alert {
  margin: 1rem;
}
.dashboard {
  padding: 1rem;
}

.col {
  max-width: 300px !important;
}

.spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin: 1rem;
  width: 256px;
  height: 256px;
  border: .75em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
}

.podcast-logo {
  max-height: 300px;
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-auto-columns: minmax(100px, auto);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.rss-link-text {
  width: 100%;
}

.tooltip2 {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.3s;
  overflow-wrap: break-word;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>