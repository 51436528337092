<template>
<div class="d-flex justify-content-center">
  <div
      class="spinner-border m-5"
      style="width: 4rem; height: 4rem;"
      role="status"
      v-show="loading"
    >
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
</template>

<script>
import apiClient from "../services/api-common";

export default {
  name: "ValidatePage",
  components: {},
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async validateEmail() {
      var token = this.$route.query.token;
      this.loading = true;
      apiClient.post("/api/validate", {token: token}).then(
        () => {
          this.$router.push('/').then( () => {
            this.emitter.emit('sendNotification', { text: 'Ваш email успешно верифицирован.', type: "success" });
          });
        },
        (error) => {
          var message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.emitter.emit('sendNotification', { text: message, type: "error" });
        }
      ).then(
        () => {
          this.loading = false;
        }
      );
    }
  },
  beforeMount() {
    this.validateEmail()
  },
}
</script>
