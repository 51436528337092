<template>
  <div class="container">
    <div v-html="markdownToHtml"></div>
  </div>
</template>

<script>
import { marked } from 'marked';

export default {
  name: "ChangelogPage",
  data() {
    return {
      markdown: "",
    };
  },
  components: {},
  methods: {
    parseFile() {
      fetch('CHANGELOG.md')
      .then(response => response.text())
      .then(data => this.markdown = data);
    }
  },
  computed: {
    markdownToHtml(){
      return marked(this.markdown);
    }
  },
  beforeMount(){
    this.parseFile();
  },
};
</script>

<style>
</style>