<template>
  <div class="dashboard container">

    <div class="d-flex justify-content-center">
      <div
          class="spinner-border m-5"
          style="width: 4rem; height: 4rem;"
          role="status"
          v-show="loading"
        >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div v-if="errorMessage" class="alert alert-danger" role="alert">
      {{ errorMessage }}
      <br>
      <p>Попробуйте <a href="/login">перезайти</a></p>
    </div>
  
    <div v-if="emailUnverified" class="alert border bg-light">
        <h4>Ваш адрес не верифицирован</h4>
        <p>
          Вам отправлено письмо с адреса podcast-rss@yandex.ru.
          Проверьте почту и папку "Спам" на наличие письма с темой
          "Проверка почты PodcastRSS". Кликните по ссылке в письме.
        </p>
        <p>
          Пока вы не верифицируете вашу почту, у вас не будет доступа к подкастам.
        </p>
    </div>
  
    <div v-if="!podcastsData.length && !errorMessage && !loading" class="alert border bg-light">
        <h4>Нет активных подписок</h4>
        <p>Это нормально для первых дней после регистрации.</p>
        <p>
          Доступ к подкастам предоставляется вручную. Подождите, пока авторы загрузят новые списки подписчиков.
          Подробнее можете прочитать в <router-link to="/faq">FAQ</router-link>.
        </p>
        <p>Надеемся на понимание.</p>
    </div>
    
    <div class="container">
      <div v-if="podcastsData" class="row row-cols-auto gy-3">
        <div v-for="(podcast, index) in podcastsData" :key="podcast" class="col" >
        <div class="p-3 border rounded bg-light">
          <img :src="podcast.logo_url" class="podcast-logo">
          <h4>{{podcast.podcast_title}}</h4>
          <p>{{podcast.level_name}}</p>
          <p>Ссылка для подкастоприемника</p>
        
          <input :value="podcast.rss_link" class="rss-link-text" readonly :id="'rss-link-text-' + index">
          <br><br>

          <div class="tooltip2 d-grid">
            <button class="btn btn-primary btn-block" v-on:click="copyToClipboard(index)" v-on:mouseout="outFunc(index)">
              <span class="tooltiptext" :id="'rss-link-tooltip-' + index">Скопировать ссылку для подкастоприемника в буфер обмена</span>
              Скопировать
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import apiClient from "../services/api-common";

export default {
  name: "HomePage",
  components: {},
  data() {
    return {
      loading: false,
      podcastsData: "",
      errorMessage: "",
      emailUnverified: false
    }
  },
  methods: {
    fortmatResponse(res) {
      return JSON.stringify(res, null, 2);
    },
    async getPodcasts() {
      this.loading = true;
        apiClient.get("/api/dashboard").then(
        (response) => {
          if ("podcasts" in response.data)
            this.podcastsData = response.data.podcasts;
          if ("email-unverified" in response.data)
            this.emailUnverified = response.data["email-unverified"];
        }
      ).catch(
        (error) => {
          console.log(JSON.stringify(error.response));

          switch (error.response.status) {
            case 401:
              this.$router.push('/login').then( () => {
                this.emitter.emit('sendNotification', { text: 'Вы не авторизовались. Зайдите в систему.', type: "error" });
              });
              break;
            case 403:
              this.$router.push('/login').then( () => {
                this.emitter.emit('sendNotification', { text: 'Ваши авторизационные данные устарели. Войдите в систему еще раз.', type: "error" });
              });
              break;
            default:
          }

          this.errorMessage =
            error.message ||
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.toString();
          }
        ).then(
        () => {
          this.loading = false;
        }
      );
    },
    copyToClipboard(id) {
      /* Get the text field */
      var copyText = document.getElementById("rss-link-text-" + id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      
      var tooltip = document.getElementById("rss-link-tooltip-" + id);
      tooltip.innerHTML = "Скопировано: " + copyText.value;
    },
    outFunc(id) {
      var tooltip = document.getElementById("rss-link-tooltip-" + id);
      tooltip.innerHTML = "Скопировать ссылку для подкастоприемника в буфер обмена";
    },
  },
  beforeMount(){
    this.getPodcasts();
  },
};
</script>

<style>
.alert {
  margin: 1rem;
}
.dashboard {
  padding: 1rem;
}

.col {
  max-width: 300px !important;
}

.spinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin: 1rem;
  width: 256px;
  height: 256px;
  border: .75em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
}

.podcast-logo {
  max-height: 300px;
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-auto-columns: minmax(100px, auto);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.rss-link-text {
  width: 100%;
}

.tooltip2 {
  position: relative;
  display: inline-block;
  width: 100%;
}

.tooltip2 .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -110px;
  opacity: 0;
  transition: opacity 0.3s;
  overflow-wrap: break-word;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
