import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  }
});
