<template>
  <div class="notifications" v-for="msg in notifications" :key="msg">
    <div v-if="msg.type == 'success'" class="alert alert-success" role="alert">
      <p>{{msg.text}}</p>
    </div>
    <div v-if="msg.type == 'info'" class="alert alert-secondary" role="alert">
      <p>{{msg.text}}</p>
    </div>
    <div v-if="msg.type == 'warning'" class="alert alert-warning" role="alert">
      <p>{{msg.text}}</p>
    </div>
    <div v-if="msg.type == 'error'" class="alert alert-danger" role="alert">
      <p>{{msg.text}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationCenter",
  data() {
    return {
      notifications: []
    }
  },
  methods: {
    clearNotifications() {
      var timestamp = Date.now();
      this.notifications = this.notifications.filter(
        item => (timestamp - item.created) < 2000
      );
    }
  },
  mounted() {
    this.emitter.on('sendNotification', (msg) => {
      this.clearNotifications();
      var created = Date.now();
      msg.created = created;
      this.notifications.push(msg);
    });
    this.emitter.on('clearNotifications', () => {
      this.clearNotifications();
    });
  },
};
</script>
