<template>
  <div id="nav">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <div class="container-fluid">
      <router-link to="/" class="navbar-brand">
          PodcastRSS
      </router-link>
      
      <span v-if="isLoggedIn">
        <a @click="logout">Logout</a>
      </span>

      <span v-else>
        <div class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link to="/faq" class="nav-link">
                FAQ
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/register" class="nav-link">
              Регистрация
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              Вход
            </router-link>
          </li>
        </div>
      </span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>
