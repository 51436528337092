<template>
<div class="container">

  <div class="bd-intro pt-2 ps-lg-2">
    <div class="d-md-flex">
      <h1 class="bd-title mb-0" id="content">Частые вопросы</h1>
    </div>
    <p class="bd-lead">Все что может вызывать вопросы о сервисе собрано на этой странице.</p>
  </div>

  <div class="bd-content ps-lg-2">
    <h2 id="where-are-my-podcasts">
      Где мои подкасты?
      <a class="anchor-link" href="#where-are-my-podcasts" aria-label="Ссылка на секцию: Где мои подкасты?"></a>
    </h2>
    <p>
      Скорее всего, вы недавно зарегистрировались и вам еще не присвоен нужный уровень подписки на подкаст.
      Подождите несколько дней, уровни проставляются вручную.
    </p>
    <p>
      Второй вариант - вы зарегистрировались с адресом несовпадающим с указанным на Бусти. Поменяйте адрес на Бусти или
      зарегистрируйте нового пользователя. Подождите пока вам выставят нужный уровень подписки.
    </p>

    <h2 id="no-active-subscriptions">
      Почему я вижу "Нет активных подписок"?
      <a class="anchor-link" href="#no-active-subscriptions" aria-label="Ссылка на секцию: Почему я вижу 'Нет активных подписок'?"></a>
    </h2>
    <p>
      После вашей регистрации авторы подкаста еще не успели загрузить новый список подписчиков на сервис.
      Без списка сервис не знает, какой уровень подписки необходимо вам выставить.
    </p>
    <p>
      Сервис не собирает адреса без разрешения, до регистрации. Поэтому, сервис не хранит адреса
      незарегистрированных пользователей полученные из ранее загруженных списков.
    </p>
    <p>
      У Бусти нет возможности для автоматической синхронизации с внешними сервисами. Поэтому, авторы загружают
      списки вручную. Проявите терпение и вы получите доступ к персональной RSS ссылке. 
    </p>

    <h2 id="way-to-get-rss-faster">
      Как быстрее получить доступ к RSS?
      <a class="anchor-link" href="#way-to-get-rss-faster" aria-label="Ссылка на секцию: Как быстрее получить доступ к RSS?"></a>
    </h2>
    <p>
      Лучший вариант - подождать.
    </p>
    <p>
      Крайне не рекомендуем обращаться к авторам подкаста и просить их загрузить список подписчиков.
      Вас много, а авторов мало. Скорее всего, их заранее задолбали такие просьбы. Будьте чуткими и внимательными к другим. 
    </p>

    <h2 id="email-is-not-verified">
      Почему я вижу "Ваш адрес не верифицирован"?
      <a class="anchor-link" href="#email-is-not-verified" aria-label="Ссылка на секцию: Почему я вижу 'Ваш адрес не верифицирован'?"></a>
    </h2>
    <p>
      Вы не прошли верификацию адреса электронной почты. Сервис проверяет, что адрес действительно принадлежит зарегистрировавшемуся пользователю.
      Во избежание случаев кражи личности.
    </p>
    <p>
      На ваш адрес было отправлено письмо с адреса "podcast-rss@yandex.ru" со ссылкой. Проверьте вашу почту и папку "Спам" на наличие письма с темой
      "Проверка почты PodcastRSS". Кликните по ссылке находящейся в письме.
    </p>


    <h2 id="how-to-use-rss-link">
      Как добавить ссылку в подкастоприемник?
      <a class="anchor-link" href="#how-to-use-rss-link" aria-label="Ссылка на секцию: Как добавить ссылку в подкастоприемник?"></a>
    </h2>
    <p>
      Данный раздел находится в разработке. Пока можем предложить ознакомиться с инструкциями с <a href="https://support.patreon.com/hc/en-us/articles/115005429546">Patreon</a>.
    </p>

    <h2 id="i-still-have-a-question">
      Я не нашел ответа на свой вопрос, что делать?
      <a class="anchor-link" href="#i-still-have-a-question" aria-label="Ссылка на секцию: Я не нашел ответа на свой вопрос, что делать?"></a>
    </h2>
    <p>
      Отправьте письмо на <a href="mailto:podcast-rss@yandex.ru">podcast-rss@yandex.ru</a> со своим вопросом. Дождитесь ответа.
    </p>
  </div>
</div>

</template>

<script>
export default {
  name: "FaqPage",
};
</script>

<style scoped>
.anchor-link {
 padding:0 .175rem;
 font-weight:400;
 color:rgba(13,110,253,0.5);
 text-decoration:none;
 opacity:0;
 transition:color 0.15s ease-in-out,opacity 0.15s ease-in-out
}
@media (prefers-reduced-motion: reduce) {
 .anchor-link {
  transition:none
 }
}
.anchor-link::after {
 content:"#"
}
.anchor-link:focus,
.anchor-link:hover,
:hover>.anchor-link,
:target>.anchor-link {
 color:#0d6efd;
 text-decoration:none;
 opacity:1
}
</style>