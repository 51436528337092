<template>
  <NavBar />
  <NotificationCenter />

  <router-view />

  <PageFooter />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import NotificationCenter from "@/components/NotificationCenter.vue";
import PageFooter from "@/components/PageFooter.vue";

export default {
  components: {
    NavBar,
    NotificationCenter,
    PageFooter,
  },
};
</script>
