<template>
  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <modalDialog :show="showModal" @close="showModal = false">
      <template #header>
        <h3>Disclaimer</h3>
      </template>
      <template #body>
        <p>
          Регистрируясь на данном сайте вы осознаете, что сервис разработан не
          профессионалом и согласны на повышенный риск компрометации ваших
          данных: адреса электронной почты, информации о подписках на boosty.to
          и вашем уровне спонсорства.
        </p>
      </template>
      <template #footer>
        <button
          class="btn btn-primary btn-block"
          @click="showModal = false"
        >Я согласен с повышенным риском</button>
      </template>
    </modalDialog>
  </Teleport>

  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      />
      <Form @submit="handleRegister" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group">
            <label for="email">Email</label>
            <Field name="email" type="email" class="form-control" />
            <ErrorMessage name="email" class="error-feedback" />
            <div class="text-center">
              <small class="text-muted">Должен совпадать с указанным на Бусти</small>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Пароль</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>

          <div class="form-group d-grid">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Зарегистрироваться
            </button>
          </div>

          <div
            id="captcha-container"
            class="smart-captcha"
            data-sitekey="ZwdK3GhagZpPabDmFTTwuZPCTVjWRZXZxfZcdkGk"
            style="height: 100px"
          ></div>

        </div>
      </Form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "../services/api-common";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ModalDialog from '@/components/ModalDialog.vue';


export default {
  name: "RegisterPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    ModalDialog,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
    });

    return {
      showModal: true,
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  created() {
    const $script = document.createElement('script');
    $script.async = true;
    $script.src = 'https://captcha-api.yandex.ru/captcha.js';
    document.head.appendChild($script);
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      var captcha = document.getElementsByName("smart-token")[0].value;
      if (!captcha) {
        this.message = "Вы не прошли капчу";
        console.log("validate failed");
          this.loading = false;
        return false;
      }
        console.log("validate accepted");
      
      apiClient.post("/api/register", {
          username: user.email,
          password: user.password,
          captcha: captcha
        }).then(
        () => {
          this.$router.push('/').then( () => {
            var text = 'Вы успешно зарегистрировались. На вашу почту отправлено письмо, подтвердите ваш адрес по ссылке в письме. И дождитесь, пока авторы загрузят новые списки. Это происходит ВРУЧНУЮ.';
            this.emitter.emit('sendNotification', { text: text, type: "success" });
          });
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      ).then(
        () => {
          this.loading = false;
        }
      );
    }
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
  margin-bottom: .5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.card-container.card {
  max-width: 380px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}

.modal-container {
  width: 600px !important;
}
</style>
